<template>
  <div class="bg-white q-pr-md text-subtitle1 page-title text-right"></div>

  <div class="q-px-lg" style="width: 485px; margin: 0 auto">
    <div class="xq-ml-sm q-mb-xl q-mt-xl text-subtitle1">Type your Credentials and click Login</div>

    <q-form>
      <div class="row q-col-gutter-x-md">
        <div class="col-12 col-sm-6" style="overflow: auto">
          <QInputBase
            v-letters-numbers-and-dash
            v-model="UserName"
            label="User Name"
            bg-color="white"
            dense
            outlined
            maxlength="15"
            autocomplete="login UserName"
          ></QInputBase>
        </div>
        <div class="col-12 col-sm-6" style="overflow: auto">
          <QInputBase v-model="Password" type="password" maxlength="20" label="Password" autocomplete="login Password"></QInputBase>
        </div>
      </div>

      <div class="row q-mt-lg">
        <div class="col-12 text-right">
          <QButtonBase label="Login" @click="Login" icon="login"></QButtonBase>
        </div>
      </div>
    </q-form>

    <div class="q-mt-xl text-caption text-grey-7">
      <q-separator></q-separator>
      <!-- <div style="line-height: 16px" class="q-mt-sm">
        Call (800) 243-8200 x.9218 to register new accounts or forgotten passwords
      </div> -->

      <div style="line-height: 16px" class="q-mt-sm">
        e-mail IT Support @
        <a class="a2" href="mailto:SE IT <se_it@southeasternaluminum.com>?subject=SEA In-House Tools Help"> SET IT Help</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import store from "@/store";
import router from "@/router";

import { ref, onBeforeMount } from "vue";

import { MsgBox, ShowLoading, HideLoading } from "../library";

import QInputBase from "@/controls/QInputBase.vue";
import QButtonBase from "@/controls/QButtonBase.vue";

name: "Login";

const API_PATH = process.env.VUE_APP_API_PATH;
const headers = { "Content-Type": "application/json" };

const UserName = ref("bryan");
const Password = ref("1012");

// redirects to the main many if user already logged in
onBeforeMount(() => {
  if (store.state.Depot.IsLogged) {
    router.replace({ path: "/main" });
  }
});

async function Login() {
  try {
    if (UserName.value === "") {
      MsgBox("Please type your <u>User Name</u>");
    } else if (Password.value === "") {
      MsgBox("Please type your <u>Password</u>");
    } else {
      ShowLoading("Validating Credentials");

      // checkLogin arguments
      const args = {
        UserName: UserName.value,
        Password: Password.value
      };

      // checks login and returns only the data part from results.data
      const { data } = await axios.post(`${API_PATH}/api/inhouse/CheckLogin`, args, { headers });

      // checks if no user was returned because of invalid credentials
      if (data.isError) {
        HideLoading();
        MsgBox(data.message);

        return;
      }

      // saves the logged state and customer info in the vuex store
      await store.dispatch("Depot/IsLogged", true);
      await store.dispatch("Depot/UserInfo", data.result);

      // redirects to the main menu
      HideLoading();

      router.replace({ path: "/main" });
    }
  } catch (err) {
    HideLoading();
    MsgBox(err.message);
  }
}
</script>
